import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import IntroCopy from "../../components/IntroCopy";
import FullWidthInfo from "../../components/FullWidthInfo";
import ImageGallery from "../../components/ImageGallery";
import ImageLockup from "../../components/ImageLockup";

const LeadworkPage = ({ data }) => (
  <Layout>
    <main>
      <SEO title="Portfolio - Leadwork" />
      <IntroCopy
        headline="Leadwork"
        body="Our Tradesmen can provide a wide range of lead roofing services including, flashings, valleys, cladding, Lead Bay Roofing etc."
        topPadding
      />
      <ImageGallery
        images={data.images.nodes.filter(image => image.name === "1")}
      />
      <FullWidthInfo
        headline="Leadwork"
        body="Leads malleable nature has meant that is it an excellent and extremely useful material in roofing. However, its takes patience, feel and no little skill to install correctly. Lead bossing is a dying trade but our skilled staff understand the importance of carrying it out correctly and neatly."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "2")}
        right={data.images.nodes.filter(image => image.name === "3")}
      />
      <FullWidthInfo
        headline="Lead Bays & Gullys"
        body="Sheet Lead work is a popular option on traditional as well as modern properties. It combines long life expectancy with satisfying appearance. Our staff understand the nature of lead and the way it reacts to seasonal climate changes. Also more importantly how to allow the lead to expand and contract whilst obviously remaining 100% watertight."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "4")}
        right={data.images.nodes.filter(image => image.name === "5")}
      />
      <FullWidthInfo
        headline="Lead Window Repair"
        body="Here we were called to a property where a lead bay window was victim of attempted theft. The window was sympathetically repaired whilst staying in budget."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "6")}
        right={data.images.nodes.filter(image => image.name === "7")}
        withLabels
      />
    </main>
  </Layout>
);

export const query = graphql`
  query LeadworkImages {
    images: allFile(
      filter: { relativeDirectory: { eq: "portfolio/leadwork" } }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default LeadworkPage;
